import React from "react";
import styled from "styled-components";
import Title from "../components/Title";

const WebDevPage = () => {
    return (
            <AboutUs>
                <Title title="Wkrótce..." />
            </AboutUs>
    );
};

export default WebDevPage;

const AboutUs = styled.div`
    width: 50%;
    padding: 40px 0;
    margin: 100px;
    @media (max-width: 780px) {
        margin: 50px;
    }
`;
