import React from 'react';
import styled from 'styled-components'

const Square = props => {
    return (
        <SquareWrapper>
            <h4>{props.title}</h4>
            <div>
                <p>{props.price}</p>
                <p>{props.speed}</p>
            </div>
            <p>{props.bottomText}</p>
        </SquareWrapper>);
}

export default Square;

const SquareWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #DC214C;
    width: 210px;
    height: 210px;
    box-shadow:  8px 8px 0px 0px white, 15px 15px 0px 0px rgba(0,0,0,0.75);
    @media(max-width: 1600px) {
        width: 170px;
        height: 170px;
    }
    @media(max-width: 1200px) {
        margin-bottom: 40px;
    }
    @media(max-width: 1024px) {
        width: 140px;
        height: 140px;
    }
    @media(max-width: 780px) {
        width: 125px;
        height: 125px;
    }
    @media(max-width: 578px){
        width: 120px;
        height: 120px; 
        box-shadow:  5px 5px 0px 0px white, 10px 10px 0px 0px rgba(0,0,0,0.75);
    }
    &::before { 
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        border-style: solid;
        border-width: 0 0 200px 100px;
        border-color: transparent transparent rgba(0,0,0,0.1) transparent;
        @media(max-width: 1600px) {
            border-width: 0 0 160px 100px;
        }
        @media(max-width: 1024px) {
            border-width: 0 0 130px 100px;
        }
        @media(max-width: 780px) {
            border-width: 0 0 115px 100px;
        }
        @media(max-width: 578px){
            border-width: 0 0 90px 60px;
        }
    }
    & h4 {
        font-size: 1.7rem;
        font-weight: 800;
        padding: 10px 0 0 10px;
        align-self: flex-start;
        @media(max-width: 1600px) {
            font-size: 1.5rem;
        }
    }
    & div {
        text-align: center;
        & p {
            padding: 2px;
            &:first-child {
                font-size: 1.4rem;
                font-weight: 600;
            }
        }
    }
    &  > p {
        font-size: 1rem;
        padding: 0 10px 10px 0;
        align-self: flex-end;
    }
`;