import React from "react";
import styled from "styled-components";

const Footer = () => {
    return (
        <SectionContainer>
            <SectionWrapper>
                <ContactWrapper>
                    <ContactElement title="Telefon" content="693 437 091" />
                    <ContactElement
                        title="Adres"
                        content="Poznańska 27 Zbąszynek"
                    />
                    <ContactElement
                        title="Nazwa firmy"
                        content="PHU Ap Serwis Agnieszka Kaczmarek ©"
                    />
                    <ContactElement title="Mail" content="kaczazb@wp.pl" />
                </ContactWrapper>
                <DescriptionWrapper>
                    <H2>
                        <h2>Kim jesteśmy i czym sie zajmujemy</h2>
                    </H2>
                    <p>
                        Firma AP Serwis świadczy usługi w dostawie internetu na
                        terenie Gminy Zbąszynek dla takich miejscowości jak
                        Dąbrówka Wielkopolska, Kosieczyn, Rogoziniec, Bronikowo.
                        Mamy wieloletnie doświadczenie na rynku, które pozwala
                        nam zadowolić naszych klientów. Firma regularnie się
                        rozwija poprzez inwestycje w sprzęt i infrastrukturę
                        oraz modernizację sieci staramy się zapewniać usługi na
                        najwyższym poziomie
                    </p>
                </DescriptionWrapper>
            </SectionWrapper>
        </SectionContainer>
    );
};

export default Footer;

const ContactElement = ({ title, content }) => {
    return (
        <Wrapper>
            <Header>{title}</Header>
            <Paragraph> {content} </Paragraph>
        </Wrapper>
    );
};

const SectionContainer = styled.div`
    padding: 100px 0 200px;
    background-color: #000;
    @media (max-width: 1200px) {
        padding: 50px 0 100px;
    }
    @media (max-width: 1024px) {
        padding: 50px 0 0;
    }
`;

const SectionWrapper = styled.footer`
    display: flex;
    justify-content: center;
    color: #fff;
    max-width: 1500px;
    margin: 0 auto;
    @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
    }
`;

const ContactWrapper = styled.div`
    display: flex;
    width: 50%;
    margin-top: 30px;
    font-size: 0.9rem;
    @media (max-width: 1024px) {
        width: 70%;
        margin: 0 auto 0 auto;
        padding: 60px 0;
    }
    @media (max-width: 576px) {
        flex-wrap: wrap;
        width: 100%;
        padding: 35px 0 35px;
    }
`;

const Wrapper = styled.div`
    width: 240px;
    width: 170px;
    text-align: center;
    @media (max-width: 576px) {
        width: 50%;
        padding: 10px 0;
    }
`;

const Header = styled.h3`
    font-weight: 700;
    font-size: "1.7rem";
    padding: 5px 5px 15px 5px;
    @media (max-width: 576px) {
        padding: 5px;
    }
`;

const Paragraph = styled.p`
    width: 80%;
    margin: 0 auto;
    font-weight: 300;
    font-size: "1.7rem";
    padding: 5px;
`;

const H2 = styled.div`
    display: inline-block;
    width: auto;
    background-color: #fff;
    padding: 10px 15px;
    color: #000;
    @media (max-width: 576px) {
        width: 100%;
    }
    & h2 {
        padding: 0 150px 0 0;
        font-size: 1.3rem;
        font-weight: 500;
        @media (max-width: 576px) {
            padding: 0 20px 0 0;
        }
    }
`;

const DescriptionWrapper = styled.div`
    width: 50%;
    @media (max-width: 1024px) {
        width: 500px;
        margin: 0 auto;
        order: -1;
    }
    @media (max-width: 576px) {
        width: 90%;
    }
    & p {
        width: 80%;
        font-size: 0.9rem;
        padding: 30px 0 0 0;
        line-height: 1.6rem;
        @media (max-width: 1200px) {
            width: 90%;
        }
    }
`;
