import React from 'react';
import styled from 'styled-components'

class Title extends React.Component {
    render() {
        return (<div><H2><h2>{this.props.title}</h2></H2></div>);
    }
}

export default Title;

const H2 = styled.div`
    display: inline-block;
    width: auto;
    background-color: black;
    padding: 10px 0 10px 10px ;
    color: #fff;
    & h2 {
        padding: 0 150px 0 0;
        font-size: 1.6rem;
        font-weight: 500;
        @media(max-width: 1600px){
            font-size: 1.4rem;
        }
        @media(max-width: 1200px) {
            font-size: 1.2rem;
        }
        @media(max-width: 1024px) {
            padding: 0 100px 0 0;
        }
        @media(max-width: 578px){
            padding: 0 20px 0 0;
            font-size: 1.1rem;
            display: block;
        }
    }
    @media(max-width: 578px){
            width: 100%;
    }
    
`;