import React from 'react';
import Ofert from '../components/Ofert'
import styled from 'styled-components'

const OfertPage = () => {
    return (
        <Wrapper>
            <Ofert />
        </Wrapper>
    );
}

export default OfertPage;

const Wrapper = styled.div`
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -150px;
    @media(max-width: 1200px){
        width: 92.5%;
        margin: -150px auto 0;
    }
    @media(max-width: 780px) {
        max-width: 500px;
        margin: 0px auto 0;
    }
`;