import React from 'react';
import styled from 'styled-components';


const Contact = props => {
    return (
        <Container>
            <ContactElement title='Telefon' content='693 437 091' />
            <ContactElement title='Adres' content='Poznańska 27 Zbąszynek' />
            <ContactElement title='Nazwa firmy' content='PHU Ap Serwis Agnieszka Kaczmarek ©' />
            <ContactElement title='Mail' content='kaczazb@wp.pl' />
        </Container>);
}

export default Contact;

const ContactElement = ({ title, content }) => {
    return (
        <Wrapper>
            <Header>{title}</Header>
            <Paragraph> {content} </Paragraph>
        </Wrapper>
    );
}

const Container = styled.div`
    display: flex;
    margin-top: 30px;
    @media(max-width: 576px){
        flex-wrap: wrap;
    }
`;

const Wrapper = styled.div`
    width: '240px';
    width: 170px;
    text-align: center;
    @media(max-width: 576px){
        width: 50%;
        padding-bottom: 20px;
    }
`;

const Header = styled.h3`
    font-weight: 700;
    font-size: '1.7rem';
    padding: 5px 5px 15px 5px;
    @media(max-width: 576px){
        padding: 5px;
    }
`;

const Paragraph = styled.p`
    width: 80%;
    margin: 0 auto;
    font-weight: 300;
    font-size: '1.7rem';
    padding: 5px;
`;

