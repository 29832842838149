import React from 'react';
import styled from 'styled-components';
import Title from './Title';
import Deal from './Deal';
import moon from '../img/moon.svg';
import contract from '../img/contract.svg';
import speed from '../img/speed.svg';

const Deals = () => {
    return (
        <Container>
            <Title title='Korzystając z naszego Internetu zyskujesz!' />
            <Wrapper>
                <Deal title='Taryfa nocna' content='Od 22:30 do 15:00 wszystkie taryfy działają z podwójną prędkością.' src={moon} />
                <Deal title='Umowa bezterminowa' content='Możesz ściągać dowolne ilości danych bez obawy, że przekroczysz limit.' src={contract} />
                <Deal title='Brak limitów' content='Tylko jeden miesiąc wypowiedzenia usługi.' src={speed} />
            </Wrapper>
        </Container>
    );
}

export default Deals;

const Container = styled.div`
    max-width: 1000px;
    margin-top: 300px;
    @media(max-width: 1600px) {
        max-width: 80%;
    }
    @media(max-width: 1200px) {
        margin-top: 200px;
        max-width: 100%;
    }
    @media(max-width: 1024px) {
        margin-top: 150px;
    }
    @media(max-width: 780px) {
        margin-top: 100px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
    @media(max-width: 1200px) {
        padding-top: 50px;
    }
    @media(max-width: 576px) {
        flex-wrap: wrap;
    }
`;