import React from 'react';
import Informations from '../components/Informations'
import styled from 'styled-components'

const InfoPage = () => {
    return (
        <Wrapper>
            <Informations />
        </Wrapper>
    );
}

export default InfoPage;

const Wrapper = styled.div`
    min-height: 100vh;
    width: 70%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -150px auto 0;
    @media(max-width: 1024px){
        width: 90%;
    }
    @media(max-width: 780px) {
        margin: 10px auto 0;
    } 
`;