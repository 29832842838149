import React from 'react';
import styled from 'styled-components';
import img1 from '../img/img1.jpg';
import img2 from '../img/img2.jpg';
import img3 from '../img/img3.jpg';
import img4 from '../img/img4.jpg';
import img5 from '../img/img5.jpg';

class GalleryPage extends React.Component {
    state = {
        isActive: false,
        activeImg: 0,
        images: [
            img1,
            img2,
            img3,
            img4,
            img5,
        ]
    }

    galleryHandleClick = img => {
        this.setState({
            activeImg: img,
            isActive: true,
        })
    }
    handleHideModal = () => {  
        this.setState({
            activeImg: 0,
            isActive: false,
        })
    }
    handleChangePreviousPhoto = () => {  
        if(this.state.activeImg <= 0)this.setState({ activeImg: this.state.images.length-1})
        else this.setState({activeImg: this.state.activeImg - 1})}
    handleChangeNextPhoto = () => { 
        if(this.state.activeImg >= this.state.images.length-1) this.setState({ activeImg: 0}) 
        else this.setState({ activeImg: this.state.activeImg + 1 })}
    render(){
        return (
            <>
                <WrapperGallery>
                    <img onClick={() => this.galleryHandleClick(0)} src={this.state.images[0]} alt="Widok z bloku topolowa 21"/>
                    <img onClick={() => this.galleryHandleClick(1)} src={this.state.images[1]} alt="Widok z bloku topolowa 25"/>
                    <img onClick={() => this.galleryHandleClick(2)} src={this.state.images[2]} alt="Nadajnik topolowa 25"/>
                    <img onClick={() => this.galleryHandleClick(3)} src={this.state.images[3]} alt="Nadajnik PCK"/>
                    <img onClick={() => this.galleryHandleClick(4)} src={this.state.images[4]} alt="Widok z bloku topolowa 21"/>
                </WrapperGallery>
                {this.state.isActive 
                    ? (<Modal>
                            <Cross onClick={this.handleHideModal}>
                                <div></div>
                                <div></div>
                            </Cross>
                            <div>
                                <img src={this.state.images[this.state.activeImg]} width={'500px'} alt="Galeria"/>
                                <div>
                                    <PreviousButton onClick={this.handleChangePreviousPhoto} >Poprzednie zdjęcie</PreviousButton>
                                    <NextButton onClick={this.handleChangeNextPhoto} >Następne zdjęcie</NextButton>
                                </div>
                            </div>
                        </Modal>
                    )
                    : null 
                }
            </>
        );
    }
}

export default GalleryPage;

const WrapperGallery = styled.div`
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 60px;
    margin: 200px auto 0;
    width: 1100px;
    max-height: 100vh;
    & img {
        width: 100%;
    }
    @media(max-width: 1200px){
        grid-gap: 50px;
        width: 900px;
    }
    @media(max-width: 1024px){
        grid-gap: 40px;
        width: 700px;
    }
    @media(max-width: 780px){
        margin: 80px auto 0;
        width: 90%;
        grid-template-columns: repeat(1,1fr);
    }
`;
const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .6);
    & > div{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 800px;
        @media(max-width: 1200px){
            width: 700px;
        }
        @media(max-width: 1024px){
            width: 600px;
        }
        @media(max-width: 1024px){
            top: 150px;
            left: 50%;
            transform: translateX(-50%);
            margin: 0 auto;
            width: 90%;
        }
        @media(max-width: 400px) {
            top: 100px;
        }
        & img {
            width: 100%;
        }
        & > div {
            display: flex;
            justify-content: space-between;
            padding: 20px 0;
        }
    }
`;

const Cross = styled.button`
    position: absolute;
    right: 20px;
    top: 90px;
    width: 50px;
    height: 50px;
    background-color: transparent ;
    @media(max-width: 400px) {
        top: 50px;
        width: 40px;
    }
    & div {
        width: 100%;
        height: 2px;
        background-color: #000;
        position: absolute;
        top: 50%;
        transform: rotate(-45deg) translateY(-50%);
        &:last-child {
            transform: rotate(45deg) translateY(-50%);
        }
    }
`;

const NextButton = styled.button`
    background-color: transparent;
    z-index: 99;
    position: relative;
    padding: 10px 15px;
    font-size: 1rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    transition: border 1s ease;
    overflow: hidden;
    @media(max-width: 780px){
        background-color: #000;
        color: #fff;
    }
    @media(max-width: 400px) {
        font-size: .8rem;
        padding: 7px 9px;
    }
    &::after {
        content: '';
        position: absolute;
        border-left: 1.3px solid black;
        border-right: 1.3px solid black;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transition: all .3s ease;
        transform: translateY(100%)
    }
    &::before {
        content: 'Następne zdjęcie';
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        background-color: black;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transition: transform .6s ease, color .3s ease .4s;
        transform: translateY(-100%);
        color: black;

    }
    &:hover::after {
        transform: translateY(0);

    }
    &:hover::before {
        transform: translateY(0);
        color: white;
    }
    &:hover {
        z-index: 99;
        color: #fff;
    }
    @media(max-width: 1024px) {
        z-index: 99;
        color: #fff;
        &::after {
            transform: translateY(0);
        }
        &::before {
            transform: translateY(0);
            color: white;
        }
    }
`;

const PreviousButton = styled.button`
    background-color: transparent;
    z-index: 99;
    position: relative;
    padding: 10px 15px;
    font-size: 1rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    transition: border 1s ease;
    overflow: hidden;
    @media(max-width: 780px){
        background-color: #000;
        color: #fff;
    }
    @media(max-width: 400px) {
        font-size: .8rem;
        padding: 7px 9px;
    }
    &::after {
        content: '';
        position: absolute;
        border-left: 1.3px solid black;
        border-right: 1.3px solid black;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transition: all .3s ease;
        transform: translateY(100%)
    }
    &::before {
        content: 'Poprzednie zdjęcie';
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        background-color: black;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transition: transform .6s ease, color .3s ease .4s;
        transform: translateY(-100%);
        color: black;

    }
    &:hover::after {
        transform: translateY(0);

    }
    &:hover::before {
        transform: translateY(0);
        color: white;
    }
    &:hover {
        z-index: 99;
        color: #fff;
    }
    @media(max-width: 1024px) {
        z-index: 99;
        color: #fff;
        &::after {
            transform: translateY(0);
        }
        &::before {
            transform: translateY(0);
            color: white;
        }
    }
`;
