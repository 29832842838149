import React from 'react';
import styled from 'styled-components'

const Button = props => {
    return (<EventButton text={props.title} onClick={props.onClick ? props.onClick : null}>{props.title}</EventButton>);
}

export default Button;

const EventButton = styled.button`
    background-color: transparent;
    z-index: 99;
    position: relative;
    padding: 10px 15px;
    font-size: 1rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    transition: border 1s ease;
    overflow: hidden;
    @media(max-width: 780px){
        background-color: #000;
        color: #fff;
    }
    &::after {
        content: '';
        position: absolute;
        border-left: 1.3px solid black;
        border-right: 1.3px solid black;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transition: all .3s ease;
        transform: translateY(100%)
    }
    &::before {
        content: 'Nasza strona';
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        background-color: black;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transition: transform .6s ease, color .3s ease .4s;
        transform: translateY(-100%);
        color: black;

    }
    &:hover::after {
        transform: translateY(0);

    }
    &:hover::before {
        transform: translateY(0);
        color: white;
    }
    &:hover {
        z-index: 99;
        color: #fff;
    }
    @media(max-width: 1024px) {
        z-index: 99;
        color: #fff;
        &::after {
            transform: translateY(0);
        }
        &::before {
            transform: translateY(0);
            color: white;
        }
    }
`;