import React from 'react';
import styled from 'styled-components'

const Deal = props => {
    return (
        <Wrapper src={props.src}>
            <h4>{props.title}</h4>
            <p>{props.content}</p>
            <img src={props.src} alt="" />
        </Wrapper>
    );
}

export default Deal;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 260px;
    width: 250px;
    background-color: #DC214C;
    padding: 10px 10px 10px 55px;
    overflow: hidden;
    box-shadow:  8px 8px 0px 0px white, 15px 15px 0px 0px rgba(0,0,0,0.75);
    @media(max-width: 1600px) {
        width: 220px;
        height: 230px;
        padding: 10px 10px 10px 45px;
    }
    @media(max-width: 1200px) {
        width: 200px;
        height: 210px;
    }
    @media(max-width: 1024px) {
        width: 150px;
        height: 160px;
        padding: 20px 10px 10px 30px;
    }
    @media(max-width: 780px) {
        justify-content: flex-start;
        width: 130px;
        height: 140px;
        margin: 0 10px 40px 0;      
    }
    & img {
        position: absolute;
        height: 240px;
        top: 65%;
        left: 25%;
        transform: translate(-50%, -50%);
        opacity: .1;
        @media(max-width: 1024px) {
            height: 180px;
        }
        @media(max-width: 780px) {
            height: 150px;
        }
        @media(max-width: 576px) {
            height: 140px;  
        }
    }
    & h4{
        font-size: 1.6rem;
        padding-bottom: 10px;
        font-weight: 800;
        @media(max-width: 1024px) {
            font-size: 1.4rem;
        }
    }
`;