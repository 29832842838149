import React from 'react';
import styled from 'styled-components';
import Title from './Title'

const Informations = () => {
    return (
        <Wrapper>
            <Title title='Informacje o naszych usługach' />
            <ParagraphWrapper>
                <p>Jednorazowa opłata instalacyjna 200 zł - Cena zawiera sprzęt transmisji danych, instalację, router bezprzewodowy oraz konfigurację.</p>
                <p>Taryfa nocna (od 22:30 do 15:00) wszystkie taryfy działają z podwójną prędkością.</p>
                <p>Istnieje możliwość uruchomienia większej opcji transferu - indywidualna wycena.</p>
                <p>Brak limitów pobierania danych - Możesz ściągać dowolne ilości danych bez obawy, że przekroczysz limit.</p>
                <p>Umowa bez terminowa - tylko jeden miesiąc wypowiedzenia usługi. W każdej chwili możesz zrezygnować.</p>
                <p>Instalacja od momentu zgłoszenia - 2 dni robocze oraz internetowe biuro obsługi klienta IBOK.</p>
                <p>Nie potrzebujesz linii telefonicznej i telefonu - Nie płacisz za utrzymanie linii lub abonamentu telefonicznego.</p>
                <p>Router Wifi - Do naszego łącza możesz podłączyć router wifi i cieszyć się bezprzewodowym dostępem na terenie całego domu.</p>
            </ParagraphWrapper>
        </Wrapper>
    );
}

export default Informations;

const Wrapper = styled.div`
    margin-top: 300px;
    @media(max-width: 1200px) {
            margin-top: 200px;
    }
    @media(max-width: 1024px) {
        margin-top: 150px;
    }
    @media(max-width: 780px) {
        margin-top: 100px;
    }
`;

const ParagraphWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    @media(max-width: 1200px) {
        width: 100%;
    }   
    & p {
        width: 47%;
        font-size: 1.1rem;
        padding: 30px 0 0 0;
        line-height: 2rem;
        &:nth-child(2), &:nth-child(3), &:nth-child(6), &:nth-child(7) {
            font-weight: bold;
        }
        @media(max-width: 1200px) {
            padding: 10px 0 0;
            &:nth-child(1), &:nth-child(2) {
                padding: 50px 0 0;
            }
        }
        @media(max-width: 576px) {
            width: 100%;
            &:nth-child(2), &:nth-child(3), &:nth-child(6), &:nth-child(7) {
                font-weight: 400;
            }   
            &:nth-child(1), &:nth-child(2) {
                padding: 10px 0 0;
            }
            &:nth-child(1) {
                padding: 40px 0 0;
            }
            &:nth-child(even) {
                font-weight: bold;
            }
        }
    }
`;