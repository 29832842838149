import React from "react";
import styled from "styled-components";
import NavEl from "./NavEl";
import { NavLink } from "react-router-dom";

class Nav extends React.Component {
    state = {
        isNavClicked: false,
    };
    handleButtonClick = () => {
        this.setState({ isNavClicked: !this.state.isNavClicked });
    };
    handleHideNav = () => {
        this.setState({ isNavClicked: false });
    };
    render() {
        return (
            <Wrapper>
                <Logo onClick={this.handleHideNav}>
                    <NavLink to="/">
                        <h1>Apserwis</h1>
                        <p>
                            Zbąszynek Dąbrówka WLKP Chlastawa Kosieczyn
                            Bronikowo Rogoziniec
                        </p>
                    </NavLink>
                </Logo>
                <Hamburger
                    onClick={this.handleButtonClick}
                    className={this.state.isNavClicked ? "clicked" : null}
                >
                    <div></div>
                    <div></div>
                </Hamburger>
                <NavBar className={this.state.isNavClicked ? "clicked" : null}>
                    <NavEl
                        hideNav={this.handleHideNav}
                        to="/"
                        title="Strona główna"
                    />
                    <NavEl
                        hideNav={this.handleHideNav}
                        to="/ofert"
                        title="Oferta"
                    />
                    <NavEl
                        hideNav={this.handleHideNav}
                        to="/info"
                        title="Informacje"
                    />
                    <NavEl
                        hideNav={this.handleHideNav}
                        to="/gallery"
                        title="Galeria"
                    />
                    <NavEl
                        hideNav={this.handleHideNav}
                        to="/webDeveloper"
                        title="WEB Developer"
                    />
                </NavBar>
            </Wrapper>
        );
    }
}

export default Nav;

const Logo = styled.button`
    position: relative;
    z-index: 100;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    & h1 {
        font-size: 2.6rem;
        font-weight: 700;
        text-transform: uppercase;
        @media (max-width: 1200px) {
            font-size: 2.4rem;
        }
    }
    & p {
        font-size: 0.9rem;
        font-weight: 300;
        @media (max-width: 1200px) {
            font-size: 0.8rem;
        }
        @media (max-width: 1024px) {
            display: none;
        }
    }
`;
const Hamburger = styled.button`
    display: none;
    position: relative;
    width: 30px;
    height: 10px;
    z-index: 3;
    & div {
        position: absolute;
        background-color: #000;
        width: 100%;
        height: 3px;
        &:nth-child(1) {
            top: 0;
        }
        &:nth-child(2) {
            bottom: 0;
        }
    }
    &.clicked div {
        &:nth-child(1) {
            animation: topLine 1s ease both;
        }
        &:nth-child(2) {
            animation: bottomLine 1s ease both;
        }
    }
    @media (max-width: 780px) {
        display: block;
    }
    @keyframes topLine {
        0% {
            transform: translateY(0);
            top: 0;
        }
        20% {
            top: 50%;
            transform: translateY(-50%);
        }
        100% {
            transform: translateY(-50%) rotate(45deg);
            transform-origin: center;
            top: 50%;
        }
    }
    @keyframes bottomLine {
        0% {
            transform: translateY(0);
            bottom: 0;
        }
        20% {
            bottom: 50%;
            transform: translateY(50%);
        }
        100% {
            transform: translateY(50%) rotate(-45deg);
            bottom: 50%;
            transform-origin: center;
        }
    }
`;
const NavBar = styled.ul`
    display: flex;
    align-items: center;
    @media (max-width: 780px) {
        display: block;
        position: absolute;
        justify-content: center;
        top: -110vh;
        right: 0;
        width: 100%;
        height: 100vh;
        z-index: 2;
        background-color: rgba(255, 255, 255, 0.95);
        background-size: 100%;
        background-color: rgba(255, 255, 255, 0.95);
        background-size: 100%;
        padding: 80px 0 0 0;
        transition: top 0.5s ease;
        &.clicked {
            top: 0;
        }
    }
`;
const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 10px 100px;
    background-color: #fff;
    z-index: 100;
    @media (max-width: 1600px) {
        padding: 10px 40px;
    }
    @media (max-width: 1024px) {
        padding: 10px 20px;
    }
`;
