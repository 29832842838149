import React from "react";
import styled from "styled-components";
import Title from "./Title";
import Square from "./Square";

const Ofert = () => {
    return (
        <Wrapper>
            <Title title="W naszej ofercie na pewno znajdziesz coś dla siebie"></Title>
            <p>
                W naszej ofercie znajdziesz coś dla siebie! Posiadamy wiele
                wariantów internetu aby klinet mógł wybrać najbardziej optymalną
                opcje dla niego. Jeśli żadna z ofert nie jest satysfakconująca
                zapraszamy do kontaktu i doboru indywidualnego pakietu.
            </p>
            <SquareWrapper>
                <Square
                    title="Internet 30"
                    price="30,00 zł"
                    speed="do 20Mbit/s"
                />
                <Square
                    title="Internet 40"
                    price="40,00 zł"
                    speed="do 30Mbit/s"
                />
                <Square
                    title="Internet 50"
                    price="50,00 zł"
                    speed="do 40Mbit/s"
                />
                <Square
                    title="Internet 60"
                    price="60,00 zł"
                    speed="do 50Mbit/s"
                />
                <Square
                    title="Oferta indywidualna"
                    price="Do ustalenia"
                    speed="Prędkość oraz cena"
                />
            </SquareWrapper>
        </Wrapper>
    );
};

export default Ofert;

const Wrapper = styled.div`
    margin-top: 240px;
    @media (max-width: 1200px) {
        margin-top: 200px;
    }
    @media (max-width: 1024px) {
        margin-top: 150px;
    }
    @media (max-width: 780px) {
        margin-top: 100px;
    }
    & > p {
        max-width: 600px;
        font-size: 1.1rem;
        padding: 30px 0 0 0;
        line-height: 2rem;
    }
    button {
        &::before {
            content: "IBOK";
        }
    }
`;

const SquareWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    max-width: 1200px;
    padding: 30px 0;
    & > * {
        margin-right: 40px;
    }
    @media (max-width: 1200px) {
        flex-wrap: wrap;
    }
    @media (max-width: 1024px) {
        padding: 30px 0 0 0;
    }
    @media (max-width: 576px) {
        & > * {
            margin-right: 25px;
        }
    }
`;
