import React from 'react';
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const NavEl = props => {
    return (
        <Li>
            <NavLink onClick={props.hideNav} to={props.to}>
                <Pararaph>{props.title}</Pararaph>
            </NavLink>
        </Li >
    );
}

export default NavEl;

const Li = styled.li`
    position: relative;
    list-style: none;
    cursor: pointer;
    overflow: hidden;
    margin-left: 10px;
    &::after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 1.3px;
        bottom: 0;
        background-color: #000;
        transform: translateX(110%);
        transition: transform .3s ease;
    }
    @media(min-width: 780px) {
        &:hover::after{
            transform: translateX(0);
        }
    }
    @media(max-width: 780px) {
        width: 250px;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #000;
        margin: 10px;
        text-align: center;
        margin: 0 auto;
    }
`;

const Pararaph = styled.p`
    font-weight: 300;
    font-size: 1.3rem;
    color: black;
    padding: 10px 20px;
    @media(max-width: 1600px) {
        padding: 10px 15px;
        font-size: 1.2rem;
    }
    @media(max-width: 1024px) {
        font-size: .95rem;
    }
    @media(max-width: 780px) {
        font-size: 1.5rem;
        font-weight: bold;
        padding: 15px 20px;
    }
`;
