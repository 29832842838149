import React from "react";
import styled from "styled-components";
import Title from "../components/Title";
import Button from "./Button";

const WebDevSection = () => {
    return (
        <Wrapper>
            <Title title="APSerwis Web Developer" />
            <p>
                Oferujemy również projektowanie i tworzenie stron internetowych.
                Strona WWW to nie tylko wygląd, ale też funkcjonalność.
                Nowoczesne, szybkie i responsywne serwisy, które dopasowujemy do
                potrzeb klienta to cechy jakimi się wyróżniamy. Regularnie się
                rozwijamy i przeglądamy najnowocześniejsze standardy.
                Optymalizujemy stronę tak aby każdy jej odbiorca mógł z
                przyjemnością ją przeglądać.
            </p>
            <a href="/webDeveloper"><Button title="Nasza strona"/></a>
        </Wrapper>
    );
};

export default WebDevSection;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 300px;
    width: 60%;
    @media (max-width: 1600px) {
        width: 75%;
    }
    @media (max-width: 1200px) {
        margin-top: 200px;
    }
    @media (max-width: 1024px) {
        width: 90%;
    }
    @media (max-width: 1024px) {
        margin-top: 150px;
    }
    @media (max-width: 780px) {
        margin-top: 100px;
    }
    & p {
        width: 100%;
        font-size: 1.1rem;
        padding: 30px 0;
        line-height: 2rem;
    }
    & a {
        align-self: flex-end;
    }
    @media (max-width: 576px) {
        width: 100%;
    }
`;
