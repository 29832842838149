import React from 'react';
import styled from 'styled-components';
import Title from './Title'
import Contact from './Contact'
import Square from './Square'

const ConactSection = () => {
    return (
        <Container>
            <Wrapper>
                <Title title='Chcesz dowiedzieć się więcej? ' />
                <Title title='Skontaktuj się z nami!' />
                <Contact />
            </Wrapper>
            <a href="http://78.133.206.180/ibok/"><Square title='Internetowe biuro obsługi klienta' bottomText='Kliknij...' /></a>
        </Container>
    );
}

export default ConactSection;

const Container = styled.div`
    display: flex;
    margin-top: 300px;
    @media(max-width: 1600px) {
        margin-top: 200px;
    }
    @media(max-width: 1024px) {
        margin-top: 150px;
    }
    @media(max-width: 780px) {
        margin-top: 100px;
    }
    @media(max-width: 576px){
        flex-direction: column;
        width: 100%;
    }
`;

const Wrapper = styled.div`
     @media(max-width: 1200px) {
        width: 70%;
        padding-right: 100px;
    }
    @media(max-width: 1024px) {
        width: 70%;
        padding-right: 0px;
    }
    @media(max-width: 576px) {
        width: 100%;
        padding: 0 0 35px 0;
    }
    
    & > *:nth-child(1){
        @media(max-width: 576px) {
            display: none;
        }
    }
`;