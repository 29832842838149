import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Nav from './components/Nav';
import { createGlobalStyle } from 'styled-components';
import background from './img/tło4.png';
import MainPage from './pages/MainPage';
import ErrorPage from './pages/ErrorPage';
import GalleryPage from './pages/GalleryPage';
import InfoPage from './pages/InfoPage';
import OfertPage from './pages/OfertPage';
import WebDevPage from './pages/WebDevPage';


function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Nav />
      <Switch>
        <Route path="/" exact component={MainPage} />
        <Route path="/ofert" component={OfertPage} />
        <Route path="/info" component={InfoPage} />
        <Route path="/gallery" component={GalleryPage} />
        <Route path="/webDeveloper" component={WebDevPage} />
        <Route component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;

const GlobalStyle = createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
  }
  *, *::before, *::after {
    box-sizing: border-box;
  }
  html {
    font-size: 15px;
    @media(max-width: 1600px) {
      font-size: 13px;
    }
    @media(max-width: 1200px) {
        font-size: 11px;
    }
    @media(max-width: 780px){
        font-size: 9px;
    }
  }
  body {
    background-image: url(${background});
    background-size: 100%;
    font-family: 'Montserrat', sans-serif;
  }
  body .root{
    width: 100%;
  }
  button {
    font-family: 'Montserrat', sans-serif;
    background-color: #fff;
    border: none;
    cursor: pointer;
  }
  a {
    text-decoration: none;
    color: black;
  }
`;
