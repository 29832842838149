import React, { Component } from "react";
import styled from "styled-components";
import town from "../img/town.svg";
import Title from "../components/Title";
import Ofert from "../components/Ofert";
import Informations from "../components/Informations";
import ContactSection from "../components/ContactSection";
import WebDevSection from "../components/WebDevSection";
import Deals from "../components/Deals";
import Footer from "../components/Footer";

class MainPage extends Component {
    state = {};
    render() {
        return (
            <>
                <PageWrapper>
                    <Wrapper>
                        <AboutUs>
                            <Title title="Kim jesteśmy i czym się zajmujemy?" />
                            <p>
                                Firma AP Serwis świadczy usługi w dostawie
                                internetu na terenie Gminy Zbąszynek dla takich
                                miejscowości jak Dąbrówka Wielkopolska,
                                Kosieczyn, Rogoziniec, Bronikowo. Mamy
                                wieloletnie doświadczenie na rynku, które
                                pozwala nam zadowolić naszych klientów. Firma
                                regularnie się rozwija poprzez inwestycje w
                                sprzęt i infrastrukturę oraz modernizację sieci
                                staramy się zapewniać usługi na najwyższym
                                poziomie
                            </p>
                        </AboutUs>
                        <Town src={town}></Town>
                    </Wrapper>
                    <Ofert />
                    <Informations />
                    <ContactSection />
                    <WebDevSection />
                    <Deals />
                </PageWrapper>
                <Footer />
            </>
        );
    }
}

export default MainPage;

const PageWrapper = styled.div`
    margin: 200px auto;
    max-width: 1400px;
    @media (max-width: 1600px) {
        max-width: 1024px;
    }
    @media (max-width: 1200px) {
        margin-top: 100px;
    }
    @media (max-width: 1200px) {
        max-width: 800px;
    }
    @media (max-width: 1024px) {
        max-width: 780px;
    }
    @media (max-width: 1024px) {
        max-width: 500px;
    }
    @media (max-width: 578px) {
        width: 92%;
        margin-bottom: 100px;
    }
`;

const Wrapper = styled.div`
    padding-top: 110px;
    display: flex;
    justify-content: center;
    @media (max-width: 780px) {
        justify-content: flex-start;
        padding-top: 0px;
    }
    @media (max-width: 576px) {
        flex-direction: column-reverse;
    }
`;

const Town = styled.img`
    width: 50%;
    /* margin-left: 0px; */
    @media (max-width: 1024px) {
        width: 40%;
    }
    @media (max-width: 1024px) {
        display: none;
    }
    @media (max-width: 576px) {
        display: block;
        width: 70%;
        margin: 0 auto;
    }
`;

const AboutUs = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    padding: 40px 0;
    @media (max-width: 1024px) {
        width: 100%;
    }
    & p {
        width: 70%;
        font-size: 1.1rem;
        padding: 30px 0 0 0;
        line-height: 2rem;
        @media (max-width: 1600px) {
            width: 85%;
        }
        @media (max-width: 576px) {
            width: 100%;
        }
    }
    @media (max-width: 576px) {
        padding: 10px 0;
    }
`;
